*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

#app {
  height: 100%;
}

body {
  margin: 0;
  line-height: 1.5;
  background-color: #fff;
}

.main {
  font-family: monospace;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.scan {
  margin: 15px auto;
  text-align: center;
}

.scanCanvas {
  -webkit-box-shadow:inset 0 0 0 1px #e6e6e6;
  -moz-box-shadow:inset 0 0 0 1px #e6e6e6;
  box-shadow:inset 0 0 0 1px #e6e6e6;
  border-radius: 6px;
}

.scanBtn {
  margin-top: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myHref {
  color: white;
  background-color: #2c1f9e;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  margin: 0 5px;
}

.myHref:hover {
  background-color: #2c1f9e;
  color: white;
  outline: none;
  text-decoration: none;
}

.myHref:active {
  background-color: #3223b3;
  text-decoration: none;
  outline: none;
}

.resultModal {
  position: absolute;
  width: 320px;
  transform: translateY(-100%);
  height: 520px;
  background-color: white;
  text-align: center;
}

.result {
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  padding: 20px 20px;
  white-space:pre-wrap;
  background-color: #e6e6e6;
  border-radius: 6px;
}

.ok_btn {
  width: 100px;
  color: #f6f6f6;
  margin: 10px 0;
  outline: none;
  font-family: monospace;
  font-size: 15px;
}